<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: drawPond.vue
Description: This file contains UI components requried to draw a pond on maps page
-->
<template>
  <div id="draw-pond">
    <div id="add-pond-element">
      <template v-if="mapMode === 'INITIAL'">
        <er-button
          class="btn-draw"
          size="small"
          :disabled="disabled || isReadOnly"
          key="INITIAL"
          @click="changeMode('DRAW_POND_INSTR_1')"
          >{{ $t("Comn_add_pond") }}</er-button
        >
      </template>
      <el-card
        class="action-display-panel"
        v-if="mapMode !== 'INITIAL' && map !== 'EDITING'"
        v-loading="loading"
        element-loading-background="white"
      >
        <template v-if="mapMode === 'DRAW_POND_INSTR_1'">
          <el-row class="add-pond-header" key="DRAW_POND_INSTR_1">
            <el-col>{{ $t("Map_click_field_shape_msg") }}</el-col>
            <el-col class="action-button-col">
              <el-row type="flex" justify="center">
                <er-button
                  btnType="draw"
                  size="mini"
                  :showLabel="true"
                  :showIcon="true"
                  @click="drawPond"
                >
                </er-button>
                <er-button
                  btnType="cancel"
                  :showLabel="true"
                  size="mini"
                  @click="resetDrawingMode"
                ></er-button>
              </el-row>
            </el-col>
          </el-row>
        </template>
        <template v-if="mapMode === 'DRAWING'">
          <el-row class="add-pond-header" key="DRAWING">
            <el-col>{{ $t("Map_trace_your_pond_boundaries") }}</el-col>
            <el-col class="action-button-col">
              <el-row type="flex" justify="center">
                <er-button
                  btnType="cancel"
                  :showLabel="true"
                  size="mini"
                  @click="resetDrawingMode"
                >
                  {{ $t("Comn_cancel") }}
                </er-button>
              </el-row>
            </el-col>
          </el-row>
        </template>
        <template v-if="mapMode === 'DRAW_POND_COMPLETE'">
          <template slot="header">
            <h3>{{ $t("Comn_add_pond") }}</h3>
          </template>
          <el-alert
            v-if="ehm__unhandledErrorMessage"
            :title="$t(ehm__unhandledErrorMessage)"
            @close="ehm__handleAlertClose"
            type="warning"
            style="width: 100%"
          ></el-alert>
          <er-form
            :ref="formName"
            :model="formPond"
            size="mini"
            class="form-container"
            label-position="left"
            key="DRAW_POND_COMPLETE"
            :hide-required-asterisk="true"
          >
            <el-form-item
              :label="$t('Comn_pond_name')"
              prop="title"
              :error="
                ekmm__castErrorKeyToLang(ehm__errMessagesObject['title']) ||
                  ehm__errMessagesObject.title
              "
            >
              <el-input
                v-model="formPond.title"
                maxlength="50"
                :placeholder="$t('Comn_pond_name')"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="ftm__capitalize($tc('Comn_location.case.lower', 1))"
              prop="location_id"
              :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject['name'])"
            >
              <er-select
                value-key="name"
                allow-create
                filterable
                :placeholder="
                  ftm__capitalize($tc('Comn_location.case.lower', 1))
                "
                popper-class="location-select__popper"
                default-first-option
                :value="currPondLocation"
                @input="handleChangeInLocation"
                class="select-location"
              >
                <!-- :filter-method="filterLocations" -->
                <el-option
                  v-for="location in drawPondLocations"
                  :key="`${location.name} ${location._id}`"
                  :value="location.name.toLowerCase()"
                  :label="ftm__capitalize(location.name)"
                ></el-option>
              </er-select>
            </el-form-item>
            <el-form-item
              :label="
                $tc('Pond_size', 2, { units: $t(pondSizeUnits.lang_key) })
              "
              prop="size"
              :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject['size'])"
            >
              <el-input-number
                :value="parseFloat(Number(formPond.size))"
                size="mini"
                :controls="false"
                :placeholder="
                  $tc('Pond_size', 2, { units: $t(pondSizeUnits.lang_key) })
                "
                :clearable="false"
                @change="handleAreaValueChange"
                class="draw-pond-input-size"
              ></el-input-number>
              <!--@change="handleAreaValueChange"  parseFloat(Number(formPond.size).toFixed(2).slice(0, -1))-->
              <div class="error-msg" v-if="isError">{{$t('Pond_size_error_msg', { maxArea: this.maxArea, minArea: this.minArea })}}</div>
            </el-form-item>
            <div class="btn-group">
              <er-button
                btnType="save"
                :showIcon="true"
                :loading="loading"
                :showLabel="true"
                :disabled="isError"
                size="mini"
                @click="saveDetails"
              >
              </er-button>
              <er-button
                btnType="cancel"
                :showLabel="true"
                size="mini"
                @click="resetDrawingMode"
              >
              </er-button>
            </div>
          </er-form>
        </template>
      </el-card>
    </div>
  </div>
</template>

<script>
/**
 *TODO :
 *1.need to disable save button until an overlay is drawn
 *2.need to correct error of positioning name for the concave polygons
 */
import Pond from "@/model/pond";
import { mapGetters, mapActions, mapMutations } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
import filtersMixin from "@/mixins/filtersMixin";
import notificationMixin from "@/mixins/notificationMixin";
import { permissionsToStringMap as permissions } from "@/middleware/pageAccessManager";

export default {
  mixins: [
    errorHandlerMixin,
    errorKeyMapMixin,
    redirectsMixin,
    filtersMixin,
    notificationMixin
  ],
  props: ["map"],
  data: function () {
    return {
      disabled: false,
      drawingTempSteps: 1,
      currPondLocation: "",
      formPond: new Pond(),
      ehm__errMessagesObject: {
        title: "",
        location_id: "",
        size: "",
        name: ""
      },
      pondDetailsDialog: false,
      pondLocationList: [],
      loading: false,
      formName: "pondSave",
      ekmm__backndfieldToFieldNameMap: {
        title: "Comn_pond_name",
        name: "Comn_location.lower.case",
        size: "Map_pond_size"
      },
      ekmm__backndfieldToInvalidMap: {
        size: { INVALID_VALUE: "Map_pond_size_greater0" }
      },
      componentPermissions: {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [permissions.MANAGE_CULTURE]
      },
      actualPondSize: 0,
      isValid: true,
      minArea: 0,
      maxArea: 0,
      isError: false,
    };
  },
  computed: {
    ...mapGetters("googleMaps", {
      currentPond: "getCurrDrawingPond",
      getMapMode: "getMapMode",
      getPondDrawnPlace: "getPondDrawnPlace",
      getLoadingPondDrawnPlace: "getLoadingPondDrawnPlace",
      getCanUserEdit: "getCanUserEdit"
    }),
    ...mapGetters("user", {
      getUserLocationsObj: "getUserLocationsObj",
      getUserProfile: "getUserProfile",
      getCurrUserLocation: "getCurrUserLocation",
      getUserCountryName: "getUserCountryName",
      isReadOnly: "isReadOnly",
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters("pond", {
      getPondsObj: "getPondsObj",
      getMapPondidPond: "getMapPondidPond"
    }),
    mapMode: {
      get() {
        return this.getMapMode;
      },
      set(value) {
        return this.$store.dispatch("googleMaps/setMapMode", value);
      }
    },
    drawPondLocations: {
      get() {
        return this.pondLocationList;
      },
      set(value) {
        this.pondLocationList = value;
      }
    },
    pondSizeUnits: function () {
      return this.getPreferredUnits.pond_area_units === 'ac' // this.getUserCountryName.toLowerCase() === "india" || this.getUserCountryName.toLowerCase() === "sri lanka"
        ? { units: "ac", lang_key: "Pond_size_units_ac" }
        : { units: "ha", lang_key: "Pond_size_units_ha" };
    },
  },
  mounted() {
    this.drawPondLocations = this.getUserLocationsObj;
    this.formPond = new Pond();
    this.ehm__errMessagesObject = {
      title: "",
      location_id: "",
      size: "",
      name: ""
    };
  },
  watch: {
    currentPond: function(newVal, oldVal) {
      if (this.mapMode === "DRAW_POND_COMPLETE") {
        this.formPond = this.$lodash.cloneDeep(newVal);
        this.isError = false;
        this.formPond.size = this.getPreferredUnits.pond_area_units === 'ac'
        ? this.$commonUtils.castPondAreaSize(
            +this.formPond.size,
            'ha',
            this.getPreferredUnits.pond_area_units
          ) : this.formPond.size;
        this.actualPondSize = this.formPond.size;
        console.log("this.formPond.size", this.actualPondSize);
        // this.handleAreaValueChange(this.actualPondSize);
      }
    },
    getLoadingPondDrawnPlace: function(newVal, oldVal) {
      this.loading = newVal;
    },
    getPondDrawnPlace: function(newVal, oldVal) {
      if (newVal.location_id) {
        this.currentPondLocation = newVal.toLowerCase();
      } else {
        this.currPondLocation = newVal.name.toLowerCase();
      }
    },
  },
  methods: {
    handleAreaValueChange(data) {
      this.formPond.size = data;
      // const minArea = parseFloat((this.actualPondSize - (this.actualPondSize / 100) * 20).toFixed(2).slice(0, -1));
      // const maxArea = parseFloat((this.actualPondSize + (this.actualPondSize / 100) * 20).toFixed(2).slice(0, -1));
      // console.log("actualPondSize", this.actualPondSize, minArea, maxArea, parseFloat(data.toFixed(2).slice(0, -1)));
      // if (parseFloat(data.toFixed(2).slice(0, -1)) > maxArea || parseFloat(data.toFixed(2).slice(0, -1)) < minArea) {
      //   this.isError = true;
      //   this.minArea = minArea;
      //   this.maxArea = maxArea;
      // } else {
      //   this.isError = false;
      // }
      // return this.maxArea;
    },
    ...mapActions("googleMaps", {
      currDrawingPondInMap: "currDrawingPondInMap",
      setMapMode: "setMapMode"
    }),
    ...mapMutations("googleMaps", {
      SET_ACCESS_DENIED: "SET_ACCESS_DENIED"
    }),
    ...mapActions("pond", {
      fetchAllPonds: "fetchAllPonds"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handleChangeInLocation(value) {
      this.currPondLocation = value.toLowerCase();
    },
    drawPond: function() {
      this.map.setDrawingControl();
      this.changeMode("DRAWING");
      this.currDrawingPondInMap(new Pond());
    },
    changeMode: function(mode) {
      try {
        const canUserEdit = this.getCanUserEdit;
        if (!canUserEdit) {
          this.SET_ACCESS_DENIED(true);
          return;
        }
        this.mapMode = mode;
        this.setMapMode(mode);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    resetDrawingMode: function() {
      this.ehm__errMessagesObject = {
        title: "",
        location_id: "",
        size: "",
        name: ""
      };
      this.$emit("refetch_data");
    },
    ehm__error409Handler: function(err) {
      if (err.response.data.error_code === "POND_TITLE_ALREADY_TAKEN") {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Ponds_name_already_in_use_choose_different"),
          type: "error",
          duration: 4500
        });
      } else {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Something_went_wrong"),
          type: "error",
          duration: 4500
        });
      }
    },
    saveDetails: async function() {
      // to delete unwanted key field in object\
      const loading = this.$loading({ fullscreen: true });
      try {
        this.ehm__errMessagesObject = {
          title: "",
          location_id: "",
          size: "",
          name: ""
        };
        delete this.formPond.pond_id;
        let isNewLocation = false;
        const arrLocations = Object.values(this.getUserLocationsObj);
        const foundPlaceIndex = arrLocations
          .map((x) => x.name)
          .indexOf(this.currPondLocation.toLowerCase());
        let location;
        if (foundPlaceIndex === -1) {
          location = await this.$store.dispatch("user/createLocation", {
            name: this.currPondLocation.toLowerCase()
          });
          isNewLocation = true;
        } else {
          location = arrLocations[foundPlaceIndex];
        }
        const pondNames = await this.fetchAllPonds({
          location_id: location._id,
          get_all: true,
          status: ["ACTIVE", "INACTIVE"]
        });
        const arrayOfPonds = pondNames.ponds.map((x) => x.title.toLowerCase());
        if (arrayOfPonds.includes(this.trim_nameSpace(this.formPond.title).toLowerCase())) {
          this.ehm__errMessagesObject.title = this.$t(
            "pond-name-already-taken"
          );
          return false;
        } else {
          this.formPond.location_id = location._id;
          // adding timezone to the formPond object
          this.formPond.timezone = this.getUserProfile.timezone;
          const payload = {
            title: this.trim_nameSpace(this.formPond.title),
            pond_coordinates: this.formPond.pond_coordinates,
            timezone: this.formPond.timezone,
            location_id: this.formPond.location_id,
            size: this.formPond.size,
            units: this.pondSizeUnits.units
          };
          console.log(this.getUserLocationsObj);
          if (
            this.formPond.location_id === this.getCurrUserLocation._id ||
            arrLocations.length === 0
          ) {
            await this.$store.dispatch("pond/savePondDetails", payload);
            loading.close();
            this.$notify({
              title: this.$t("Usrs_success_msg"),
              message: this.$t("Pond_details_save_success"),
              duration: 5000,
              type: "success"
            });
            this.mixPanelEventGenerator({ eventName: "Map - Add Pond - Pond Details - Save" });
          } else {
            await this.nofm__primaryConfirmation(
              this.trim_nameSpace(this.formPond.title),
              this.$t("Comn_add").toLowerCase(),
              this.$t("Comn_to").toLowerCase(),
              this.ftm__capitalize(location.name)
            );
            await this.$store.dispatch("pond/savePondDetails", payload);
            loading.close();
            this.$notify({
              title: this.$t("Usrs_success_msg"),
              message: this.$t("pond-created-successfully"),
              duration: 5000,
              type: "success"
            });
            this.mixPanelEventGenerator({ eventName: "Map - Add Pond - Pond Details - Save" });
          }
        }
        if (isNewLocation) {
          await this.$store.dispatch("user/fetchUserLocations");
          await this.$store.dispatch("user/updateCurrUserLocation", location);
          this.rdm__navigateToPathWithLocationId({
            ...this.$route.query,
            location_id: location._id
          });
        } else if (this.getCurrUserLocation._id !== location._id) {
          await this.$store.dispatch("user/updateCurrUserLocation", location);
          this.rdm__navigateToPathWithLocationId({
            ...this.$route.query,
            location_id: location._id
          });
        } else {
          this.$emit("refetch_data");
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        loading.close();
      }
    }
  }
};
</script>

<style lang="scss">
#draw-pond {
  // display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  .draw-pond-input-size {
    width: 100%;
    .el-input__inner {
      text-align: left;
    }
  }
  .action-display-panel {
    display: flex;
    flex-direction: column;
    align-content: center;
    &.el-card {
      margin: 10px;
      @include normal-text;
    }
    .btn-group {
      display: flex;
      justify-content: center;
      .er-button {
        font-size: inherit;
        // color: white;
      }
      margin-top: 12px;
    }
    .action-display-area-input {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    .el-input {
      width: 100%;
      font-size: inherit;
      display: inline-block !important;
    }
    .el-card__header,
    .el-card__body {
      padding: 10px 10px;
    }
    .add-pond-header {
      width: 200px;
      text-align: center;
      // color: white;
    }
    .action-button-col {
      margin-top: 10px;
    }
    .select-location {
      .el-input__inner {
        text-transform: capitalize;
      }
    }
  }
}
</style>
